import { Box, CircularProgress, alpha } from '@mui/material';

const Loading = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <Box
      position="fixed"
      left={0}
      top={0}
      width="100vw"
      height="100vh"
      bgcolor={alpha('#000', 0.5)}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      zIndex={50}
    >
      <CircularProgress color="primary" />
    </Box>
  );
};

export default Loading;
