import { useState } from 'react';

const links = [
  { text: 'ABOUT', url: 'https://mirl.club/#About' },
  { text: 'ROADMAP', url: 'https://mirl.club/#Roadmap' },
  { text: 'PARTNERS', url: 'https://mirl.club/#Partners' },
  { text: 'SHOP', url: 'https://shop.mirl.club/' },
  { text: 'JOIN DAO', url: 'https://mirl.club/join' },
  {
    text: 'MORE',
    isDropDown: true,
    children: [
      { text: 'MEDIA', url: 'https://mirl.club/#Media' },
      { text: 'TEAM', url: 'https://mirl.club/#Team' },
      { text: 'VOTING', url: 'https://vote.mirl.club/' },
      { text: 'CLAIM AIRDROP', url: 'https://airdrop.mirl.club/' },
      { text: 'RARITY', url: 'https://rarity.mirl.club/mirl' },
      { text: 'WEAR2EARN', url: 'https://w2e.mirl.club/' },
      { text: 'STAKING', url: '/' },
    ],
  },
];

const mobileLinks = links.reduce((result, link) => {
  if (!link.isDropDown) return [...result, link];

  return [...result, ...link.children];
}, []);

const socials = [
  {
    image: '/images/Discord.svg',
    url: 'https://discord.com/invite/qSwgPSj2RT',
  },
  { image: '/images/Twitter.svg', url: 'https://twitter.com/mirlnft' },
  { image: '/images/Telegram.svg', url: 'https://t.me/MIRL_Official' },
];

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDropdown, setIsOpenDropdown] = useState(null);

  return (
    <div className="header">
      <div className="header-container">
        <div className="logo">
          <img
            src="/images/LogoHeader.png"
            alt="Logo"
            width={140}
            height={73}
            onClick={() => (window.location.href = 'https://mirl.club/')}
          />
        </div>
        <div
          className="menu-icon"
          style={
            isOpen
              ? {
                  backgroundColor: 'rgba(133, 133, 133, 0.56)',
                  borderRadius: 8,
                }
              : {}
          }
          onClick={() => setIsOpen(!isOpen)}
        >
          <img src="/images/Menu.svg" alt="menu" width={23} height={23} />
        </div>
        {isOpen && (
          <div className="menu">
            {mobileLinks.map((item) => (
              <div
                key={item.url}
                className="menu-item"
                onClick={() =>
                  item.url.includes('http')
                    && window.open(item.url)
                }
              >
                {item.text}
              </div>
            ))}
          </div>
        )}
        <div className="content">
          <div className="links">
            {links.map((item) =>
              item.isDropDown ? (
                <div className="link dropdown-toggle" key={item.text}>
                  <span onClick={() => setIsOpenDropdown(!isOpenDropdown)}>
                    {item.text}{' '}
                    <img
                      src="/images/IconCaretDown.svg"
                      width={27}
                      height={18}
                      alt="Arrow down"
                    />
                  </span>
                  {isOpenDropdown && (
                    <div className="link-dropdown">
                      {item.children.map((children) => (
                        <div
                          key={children.url}
                          className="link-dropdown-item"
                          onClick={() => {
                            if (children.url) {
                              children.url.includes('http') &&
                                window.open(children.url);
                            } else {
                              children.onClick();
                            }
                            setIsOpenDropdown(false);
                          }}
                        >
                          {children.text}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                <div
                  key={item.text}
                  className="link"
                  onClick={() => {
                    item.url.includes('http') &&
                      window.open(item.url);
                  }}
                >
                  {item.text}
                </div>
              )
            )}
          </div>
          <div className="social-dot" />
          <div className="social-logos">
            {socials.map((item, index) => (
              <img
                src={item.image}
                alt="social"
                key={index}
                onClick={() => {
                  if (item.url) {
                    item.url.includes('http') &&
                      window.open(item.url);
                  }
                }}
                width={40}
                height={40}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
