import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import Loading from './Loading';
import useAppContext from '../hooks/useAppContext';

const steps = [0.25, 0.5, 0.75, 1];

const DepositModal = ({ isOpen, onClose, pool }) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    poolsState: { deposit },
    smartContractState: { getTokenBalance, deposit: depositWeb3 },
  } = useAppContext();

  const [isLoading, setIsLoading] = useState(false);
  const [balance, setBalance] = useState(0);
  const [amount, setAmount] = useState(0);
  console.log({balance});
  const submit = async () => {
    setIsLoading(true);
    try {
      if (amount <= 0) throw new Error('Amount must be greater than 0');
      if (amount > balance) throw new Error('Balance not enough');

      const { totalStakedAmount, stakedAmount, transactionHash } = await depositWeb3(pool.id, amount, pool.contractAddress);
      await deposit(pool.id, totalStakedAmount, stakedAmount, transactionHash);
      enqueueSnackbar(`Deposit ${amount}${pool.token} successfully`, {
        variant: 'success',
      });
      onClose();
    } catch (err) {
      console.log(err);
      enqueueSnackbar(err.message, { variant: 'error' });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isOpen) {
      getTokenBalance(pool.contractAddress)
        .then((result) => setBalance(result))
        .catch((err) => {
          console.error(err);
          enqueueSnackbar(err.message, { variant: 'error' });
          onClose();
        });
    } else {
      setAmount(0);
      setBalance(0);
    }
  }, [isOpen, pool]);

  if (!pool) return null;

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xs" fullWidth>
      <Loading isLoading={isLoading} />
      <DialogContent>
        <h3 style={{ margin: 0 }}>Deposit ${pool.tokenName}</h3>
        <Box display="flex" flexDirection="column" gap={3}>
          <Box display="flex" justifyContent="flex-end">
            <Typography>
              Your balance: {(Number(balance.toString())/(1e18)).toLocaleString()} ${pool.tokenName}
            </Typography>
          </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <TextField
            label="Amount"
            type="number"
            step="any"
            size="small"
            value={amount || ''}
            placeholder="Deposit amount"
            onChange={(e) => setAmount(Number(e.target.value))}
            onFocus={(event) => event.target.select()}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    size="small"
                    color="secondary"
                    onClick={() => setAmount(Number(balance.toString())/(1e18))}
                  >
                    Max
                  </Button>
                </InputAdornment>
              ),
            }}
          />
          <Box display="flex" justifyContent="center" gap={1.5}>
            {steps.map((step) => (
              <Button
                key={step}
                variant="outlined"
                color="secondary"
                size="small"
                onClick={() => setAmount(balance * step)}
              >
                {step * 100}%
              </Button>
            ))}
          </Box>
          </Box>
          <Button variant="contained" color="primary" onClick={submit}>
            Deposit Tokens
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DepositModal;
