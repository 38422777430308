import { createContext } from 'react';

import useAccount from '../hooks/useAccount';
import usePools from '../hooks/usePools';
import useSmartContract from '../hooks/useSmartContract';
import useProfile from '../hooks/useProfile';
import useWalletModal from '../hooks/useWalletModal';
import useProfileModal from '../hooks/useProfileModal';
import useResponsive from '../hooks/useResponsive';

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  // split to one state for each hook
  // then dont need to update this file when a hook change
  const responsiveState = useResponsive();
  const walletModalState = useWalletModal();
  const profileModalState = useProfileModal();
  const accountState = useAccount(walletModalState);
  const profileState = useProfile(accountState.user);
  const poolsState = usePools(profileState);
  const smartContractState = useSmartContract(accountState, poolsState);

  return (
    <AppContext.Provider
      value={{
        accountState,
        smartContractState,
        profileState,
        poolsState,
        walletModalState,
        profileModalState,
        responsiveState,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
