import { useState } from 'react';

const useProfileModal = () => {
  const [isProfileModalOpen, setIsOpen] = useState(false);

  const openProfileModal = () => setIsOpen(true);
  const closeProfileModal = () => setIsOpen(false);

  return { isProfileModalOpen, openProfileModal, closeProfileModal };
};

export default useProfileModal;
