import { httpsCallable } from 'firebase/functions';

import { functions } from '../configs/firebase.config';

export const callables = {
  getFirebaseAuthToken: httpsCallable(functions, 'getFirebaseAuthToken'),
  staking: {
    loadEthereumDailyBlockCount: httpsCallable(functions, 'staking-loadEthereumDailyBlockCount'),
  },
};
