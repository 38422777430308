const environments = {
  FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID:
    process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID: process.env.REACT_APP_FIREBASE_APP_ID,
  NETWORK_ID: process.env.REACT_APP_NETWORK_ID,
  CURRENCY_DECIMALS: process.env.REACT_APP_CURRENCY_DECIMALS,
  STAKING_CONTRACT_ADDRESS: process.env.REACT_APP_STAKING_CONTRACT_ADDRESS,
};

export default environments;
