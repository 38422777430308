import { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import Loading from './Loading';
import useAppContext from '../hooks/useAppContext';

const steps = [0.25, 0.5, 0.75, 1];

const WithdrawModal = ({ isOpen, onClose, pool }) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    profileState: { profile },
    poolsState: { withdraw },
    smartContractState: { withdraw: withdrawWeb3 },
  } = useAppContext();

  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState(0);

  const balance = profile?.staking?.[pool?.id]?.stakedAmount ?? 0;

  const submit = async () => {
    setIsLoading(true);
    try {
      if (amount <= 0) throw new Error('Amount must be greater than 0');
      if (amount > balance) throw new Error('Balance not enough');

      const { totalStakedAmount, stakedAmount, transactionHash } = await withdrawWeb3(pool.id, amount, pool.contractAddress);
      await withdraw(pool.id, totalStakedAmount, stakedAmount, transactionHash);
      enqueueSnackbar(`Withdraw ${amount}${pool.token} successfully`, {
        variant: 'success',
      });
      onClose();
    } catch (err) {
      console.log(err);
      enqueueSnackbar(err.message, { variant: 'error' });
    }
    setIsLoading(false);
  };

  if (!pool) return null;

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xs" fullWidth>
      <Loading isLoading={isLoading} />
      <DialogContent>
        <h3 style={{ margin: 0 }}>Withdraw ${pool.tokenName}</h3>
        <Box display="flex" flexDirection="column" gap={3}>
          <Box display="flex" justifyContent="flex-end">
            <Typography>
              Staked amount: {balance.toLocaleString()} ${pool.tokenName}
            </Typography>
          </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <TextField
            label="Amount"
            type="number"
            step="any"
            size="small"
            value={amount || ''}
            placeholder="Withdraw amount"
            onChange={(e) => setAmount(Number(e.target.value))}
            onFocus={(event) => event.target.select()}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    size="small"
                    color="secondary"
                    onClick={() => setAmount(balance)}
                  >
                    Max
                  </Button>
                </InputAdornment>
              ),
            }}
          />
          <Box display="flex" justifyContent="center" gap={1.5}>
            {steps.map((step) => (
              <Button
                key={step}
                variant="outlined"
                color="secondary"
                size="small"
                onClick={() => setAmount(balance * step)}
              >
                {step * 100}%
              </Button>
            ))}
          </Box>
          </Box>
          <Button variant="contained" color="primary" onClick={submit}>
            Withdraw Tokens
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default WithdrawModal;
