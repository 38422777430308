import { useState, useEffect, useMemo } from 'react';
import {
  collection,
  doc,
  onSnapshot,
  setDoc,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';

import { callables } from '../utils/functions';
import { firestore } from '../configs/firebase.config';

const usePools = ({ profile }) => {
  const [startDate, setStartDate] = useState(null);
  const [dailyBlockCount, setDailyBlockCount] = useState(0);
  const [mirlPerAllocPoint, setMirlPerAllocPoint] = useState(0);
  const [poolsData, setPoolsData] = useState([]);

  useEffect(() => {
    callables.staking.loadEthereumDailyBlockCount();

    const unsubscribe = onSnapshot(
      collection(firestore, 'stakingPools'),
      (snapshot) => {
        const docs = snapshot.docs.map((doc) => {
          const data = doc.data();

          return {
            ...data,
            id: doc.id,
          };
        });

        setPoolsData(docs);
      }
    );

    const unsubscribeConfig = onSnapshot(
      doc(firestore, 'configs', 'staking'),
      (snapshot) => {
        if (snapshot.exists()) {
          const {
            mirlGeneratedPerBlock,
            totalAllocPoints,
            ethereumDailyBlockCount,
            stakingStartDate,
          } = snapshot.data();
          setMirlPerAllocPoint(mirlGeneratedPerBlock / totalAllocPoints);
          setDailyBlockCount(ethereumDailyBlockCount);
          setStartDate(stakingStartDate.toDate());
        } else {
          setMirlPerAllocPoint(0);
        }
      }
    );

    return () => {
      unsubscribe && unsubscribe();
      unsubscribeConfig && unsubscribeConfig();
    };
  }, []);

  const mirlPrice = useMemo(() => {
    if (!poolsData.length) return 0;
    const mirlPool = poolsData[0];
    return mirlPool.tokenPrice;
  }, [poolsData]);

  const pools = useMemo(
    () =>
      poolsData.map((pool) => {
        const mirlPerBlock = mirlPerAllocPoint * pool.allocPoints;

        return {
          ...pool,
          mirlPerBlock,
          mirlPerDayPerToken:
            (dailyBlockCount * mirlPerBlock) / (pool.totalStakedAmount || 1),
        };
      }),
    [poolsData, mirlPerAllocPoint, dailyBlockCount]
  );

  const deposit = async (poolId, totalStakedAmount, stakedAmount, transactionHash) => {
    const pool = poolsData.find(({ id }) => poolId === id);

    if (!pool) throw new Error('Invalid pool!');
    if (!profile) throw new Error('Please connect your wallet!');

    /* update user staking amount */
    await updateDoc(doc(firestore, 'users', profile.id), {
      staking: {
        ...profile.staking,
        [poolId]: { stakedAmount },
      },
    });

    /* update pool TVL */
    await updateDoc(doc(firestore, 'stakingPools', poolId), {
      totalStakedAmount,
    });

    /* save transactionHash */
    await setDoc(doc(firestore, 'stakingTransactionLogs', transactionHash), {
      isProcessed: true,
      createdAt: serverTimestamp(),
      type: 'deposit',
      poolId,
      from: profile.id,
    });
  };

  const withdraw = async (poolId, totalStakedAmount, stakedAmount, transactionHash) => {
    const pool = poolsData.find(({ id }) => poolId === id);

    if (!pool) throw new Error('Invalid pool!');
    if (!profile) throw new Error('Please connect your wallet!');

    /* update user staking amount */
    await updateDoc(doc(firestore, 'users', profile.id), {
      staking: {
        ...profile.staking,
        [poolId]: { stakedAmount },
      },
    });

    /* update pool TVL */
    await updateDoc(doc(firestore, 'stakingPools', poolId), {
      totalStakedAmount,
    });

    /* save transactionHash */
    await setDoc(doc(firestore, 'stakingTransactionLogs', transactionHash), {
      isProcessed: true,
      createdAt: serverTimestamp(),
      type: 'withdraw',
      poolId,
      from: profile.id,
    });
  };

  return { pools, startDate, mirlPrice, deposit, withdraw };
};

export default usePools;
