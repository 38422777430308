import { useState } from 'react';

const useWalletModal = () => {
  const [isWalletModalOpen, setIsOpen] = useState(false);

  const openWalletModal = () => setIsOpen(true);
  const closeWalletModal = () => setIsOpen(false);

  return { isWalletModalOpen, openWalletModal, closeWalletModal };
};

export default useWalletModal;
