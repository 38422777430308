import { useState, useEffect, useCallback, useRef } from 'react';
import {
  signInWithCustomToken,
  onAuthStateChanged,
  signOut,
  getAuth,
} from 'firebase/auth';

import firebaseApp from '../configs/firebase.config';
import { callables } from '../utils/functions';
import useEthereum from './useEthereum';

const useAccount = (walletModalState) => {
  const {
    isInitialized: isInitializedWeb3,
    isAuthenticating: isAuthenticatingWeb3,
    account,
    signMessage,
    connectWallet,
    disconnectWallet,
  } = useEthereum();

  const onLoggedIn = useRef();
  const setOnLoggedInTrigger = (fn) => {
    onLoggedIn.current = fn;
  };

  useEffect(() => {
    if (!walletModalState.isWalletModalOpen) {
      onLoggedIn.current = null;
    }
  }, [walletModalState.isWalletModalOpen]);

  const [user, setUser] = useState(null);
  const [isInitializedFirebase, setInitializedFirebase] = useState(false);
  const [isUserDoneInitAuth, setIsUserDoneInitAuth] = useState(false);
  const [isAuthenticatingFirebase, setIsAuthenticatingFirebase] =
    useState(false);

  useEffect(() => {
    const auth = getAuth(firebaseApp);
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      setUser(firebaseUser);
      setInitializedFirebase(true);
    });

    return unsubscribe;
  }, []);

  const signInWithFirebase = useCallback(async () => {
    // console.log('sign in with firebase', { account, user });

    if (account && (!user || user.uid !== account)) {
      setIsAuthenticatingFirebase(true);
      try {
        const signedData = await signMessage();
        console.log({ signedData });

        // 2. get short-lived firebase auth token (expires in 1h)
        const { data: token } = await callables.getFirebaseAuthToken(
          signedData
        );

        // 3. signIn & trigger onAuthStateChanged
        const auth = getAuth(firebaseApp);
        await signInWithCustomToken(auth, token);
        if (onLoggedIn.current) {
          onLoggedIn.current();
          onLoggedIn.current = null;
        }
      } catch (err) {
        console.error('Error in signing in with firebase', err.message);
        // when user reject sign message
        disconnectWallet();
      }
      setIsAuthenticatingFirebase(false);
    }
  }, [account, user, signMessage]);

  useEffect(() => {
    if (isInitializedWeb3) {
      walletModalState.openWalletModal();
      if (!account) {
        const auth = getAuth(firebaseApp);
        signOut(auth).then(walletModalState.closeWalletModal);
      } else if (isInitializedFirebase) {
        if (isUserDoneInitAuth)
          signInWithFirebase().then(walletModalState.closeWalletModal);
        else walletModalState.closeWalletModal();
      }
    }
  }, [account, isInitializedWeb3, isUserDoneInitAuth, isInitializedFirebase]);

  return {
    isInitializedWeb3,
    isAuthenticatingWeb3,
    isInitializedFirebase,
    isAuthenticatingFirebase,
    account,
    user,
    setIsUserDoneInitAuth,
    signInWithFirebase,
    connectWallet,
    disconnectWallet,
    setOnLoggedInTrigger,
  };
};

export default useAccount;
