const links = [
  { text: 'ABOUT', url: 'https://mirl.club/#About' },
  { text: 'MEDIA', url: 'https://mirl.club/#Media' },
  { text: 'PARTNERS', url: 'https://mirl.club/#Partners' },
  { text: 'ROADMAP', url: 'https://mirl.club/#Roadmap' },
  { text: 'TEAM', url: 'https://mirl.club/#Team' },
];

const socials = [
  {
    image: '/images/DiscordFooter.svg',
    url: 'https://discord.com/invite/qSwgPSj2RT',
  },
  { image: '/images/TelegramFooter.svg', url: 'https://t.me/MIRL_Official' },
  { image: '/images/TwitterFooter.svg', url: 'https://twitter.com/mirlnft' },
  { image: '/images/MediumFooter.svg', url: 'https://mirlnft.medium.com/' },
  {
    image: '/images/LinkedinFooter.svg',
    url: 'https://www.linkedin.com/company/mirl/',
  },
  {
    image: '/images/InstagramFooter.svg',
    url: 'https://www.instagram.com/mirlnft/',
  },
];

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div>
          <img
            src="/images/LogoHeader.png"
            alt="footer-logo"
            width={149}
            height={77}
          />
          <div className="footer-text">© 2023 MIRL, All rights reserved.</div>
        </div>
        <div className="footer-links-socials">
          <div className="footer-links">
            <div>
              {links.slice(0, 3).map((item) => (
                <div
                  key={item.text}
                  className="footer-links-item"
                  onClick={() => window.open(item.url)}
                >
                  {item.text}
                </div>
              ))}
            </div>
            <div>
              {links.slice(3, links.length).map((item) => (
                <div
                  key={item.text}
                  className="footer-links-item"
                  onClick={() => window.open(item.url)}
                >
                  {item.text}
                </div>
              ))}
            </div>
          </div>
          <div className="footer-socials">
            {socials.map((item) => (
              <img
                src={item.image}
                alt="social"
                key={item.url}
                onClick={() => item.url && window.open(item.url)}
                width={30}
                height={30}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
