import { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import Loading from './Loading';
import useAppContext from '../hooks/useAppContext';
import { formatBigNumber } from '../utils/strings';

const ClaimModal = ({ isOpen, onClose, pool }) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    accountState: { account },
    smartContractState: { pendingMirls, getPendingMirl, claim },
    poolsState: { startDate },
  } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);

  const isStakingReady = Date.now() > startDate?.getTime();
  const amount = isStakingReady ? pendingMirls[pool?.id] ?? 0 : 0;

  useEffect(() => {
    if (account) getPendingMirl();
  }, [account, isOpen]);

  const submit = async () => {
    setIsLoading(true);
    try {
      await claim(pool.id);
      enqueueSnackbar(`Claim ${amount}$MIRL successfully`, {
        variant: 'success',
      });
      onClose();
    } catch (err) {
      console.log(err);
      enqueueSnackbar(err.message, { variant: 'error' });
    }
    setIsLoading(false);
  };

  if (!pool) return null;

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xs" fullWidth>
      <Loading isLoading={isLoading} />
      <DialogContent>
        <h3 style={{ margin: 0 }}>Claim rewards</h3>
        <Box display="flex" flexDirection="column" gap={3}>
          <Typography fontWeight="bold">
            Your reward: {formatBigNumber(amount)} $MIRL
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={submit}
            disabled={amount === 0}
          >
            {amount
              ? `Claim ${formatBigNumber(amount)} $MIRL`
              : 'Nothing to claim'}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ClaimModal;
