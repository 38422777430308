import { useMemo } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';

import useAppContext from '../hooks/useAppContext';
import { formatBigNumber } from '../utils/strings';

export default function StakingPoolItem({
  pool,
  onClickDeposit,
  onClickWithdraw,
  onClickClaim,
}) {
  const {
    profileState: { profile },
    poolsState: { mirlPrice, startDate },
    smartContractState: { pendingMirls },
  } = useAppContext();

  const APR = useMemo(
    () => (pool.mirlPerDayPerToken * 365 * mirlPrice * 100) / pool.tokenPrice,
    [pool, mirlPrice]
  );

  const isStakingReady = Date.now() > startDate?.getTime();

  return (
    <Box
      flex="1"
      borderRadius={3}
      bgcolor="#f2edeb"
      sx={{ overflow: 'hidden', fontSize: 18 }}
    >
      <Box display="flex">
        <Box flex="1" bgcolor="#5d0e99" px={2} pb={2}>
          <h3 style={{ margin: '0.5em 0 0 0' }}>{pool.title}</h3>
          <Typography fontSize={18}>
            Lock your ${pool.tokenName} token to earn extra $MIRL
          </Typography>
        </Box>
        <Box width="80px" bgcolor="white">
          <img src="/images/stake.svg" alt="" width="100%" height="100%" />
        </Box>
      </Box>
      <Box px={2} pb={2} sx={{ color: 'black' }}>
        <h4 style={{ margin: '0.5em 0 0 0' }}>Stake {pool.title}</h4>
        <Grid container>
          <StakeInfo title="Total deposited" amount={pool.totalStakedAmount} />
          <StakeInfo
            title="Est. Earning APR:"
            amount={APR}
            isBig
            isPercentage
          />
          <StakeInfo
            title="Your deposit"
            amount={profile?.staking?.[pool.id]?.stakedAmount ?? 0}
          />
          <StakeInfo
            title="Estimated earning per day"
            amount={
              isStakingReady
                ? pool.mirlPerDayPerToken *
                  (profile?.staking?.[pool.id]?.stakedAmount ?? 0)
                : 0
            }
          />
          <StakeInfo
            title="Claimable reward"
            amount={isStakingReady ? pendingMirls[pool.id] ?? 0 : 0}
          />
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'space-between', py: 1.5 }}
          >
            <Box display="flex" gap={2}>
              <Button variant="contained" size="small" onClick={onClickDeposit}>
                Deposit
              </Button>
              <Button variant="outlined" size="small" onClick={onClickWithdraw}>
                Withdraw
              </Button>
            </Box>
            <Button size="small" onClick={onClickClaim}>
              Claim
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

const StakeInfo = ({ title, amount, isBig, isPercentage }) => {
  const fontSize = isBig ? '24px' : 'inherit';
  const fontWeight = isBig ? 'bold' : '400';
  const color = isBig ? 'primary' : 'black';
  const digitsCount = isPercentage ? 2 : 3;

  return (
    <>
      <Grid item xs={6}>
        <Typography fontSize={fontSize} fontWeight={fontWeight} color={color}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography
          fontSize={fontSize}
          fontWeight={fontWeight}
          color={color}
          textAlign="right"
        >
          {formatBigNumber(amount, digitsCount)}
          {isPercentage && '%'}
        </Typography>
      </Grid>
    </>
  );
};
