import { useState } from 'react';
import {
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';

import useAppContext from '../hooks/useAppContext';

export const ConnectWalletContainer = () => {
  const {
    accountState: {
      account,
      user,
      isInitializedWeb3,
      isInitializedFirebase,
      disconnectWallet,
    },
    walletModalState: { openWalletModal },
    profileModalState: { openProfileModal },
  } = useAppContext();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderContent = () => {
    if (!account || !user)
      return (
        <button
          className="secondary-btn header-wallet-btn"
          onClick={openWalletModal}
        >
          Connect Wallet
        </button>
      );

    return (
      <div style={{ display: 'flex', gap: 8 }}>
        <Box
          sx={{
            '& ul': { padding: '0 !important' },
          }}
        >
          <div
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            className="secondary-btn header-wallet-btn"
          >
            <AccountBalanceWalletIcon sx={{ fontSize: 18 }} />
            <span style={{ fontSize: 14 }}>{`Connected ...${account.slice(
              -5
            )}`}</span>
            <KeyboardArrowDownIcon sx={{ fontSize: 14 }} />
          </div>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            sx={{
              '& .MuiPaper-rounded': {
                mt: 1,
                ml: '50px',
                width: '150px',
                maxWidth: '90vw',
                bgcolor: 'black',
                color: 'white',
              },
            }}
          >
            <MenuItem
              onClick={() => {
                openProfileModal();
                handleClose();
              }}
              sx={{
                transition: 'all ease 0.3s',
                '&:hover': { bgcolor: '#222' },
              }}
            >
              <ListItemIcon>
                <AccountCircleIcon sx={{ color: 'white' }} />
              </ListItemIcon>{' '}
            </MenuItem>
            <MenuItem
              onClick={() => {
                disconnectWallet();
                handleClose();
              }}
              sx={{
                transition: 'all ease 0.3s',
                '&:hover': { bgcolor: '#222' },
              }}
            >
              <ListItemIcon>
                <LogoutIcon sx={{ color: 'white' }} />
              </ListItemIcon>{' '}
              <ListItemText>
                <Typography fontWeight={600}>Logout</Typography>
              </ListItemText>
            </MenuItem>
          </Menu>
        </Box>
      </div>
    );
  };

  if (!isInitializedWeb3 || !isInitializedFirebase) return null;

  return <div className="connect-wallet-container">{renderContent()}</div>;
};

export const ConnectWalletModal = () => {
  const {
    walletModalState: { isWalletModalOpen, closeWalletModal },
    accountState: {
      account,
      user,
      isInitializedWeb3,
      isInitializedFirebase,
      isAuthenticatingWeb3,
      isAuthenticatingFirebase,
      setIsUserDoneInitAuth,
      connectWallet,
      disconnectWallet,
    },
  } = useAppContext();

  if (!isWalletModalOpen) return null;

  if (!isInitializedWeb3 || !isInitializedFirebase) return null;

  const renderContent = () => {
    const isAuthenticating = isAuthenticatingWeb3 || isAuthenticatingFirebase;

    if (isAuthenticating) return <p>Connecting your wallet. Please wait...</p>;

    if (!account || !user)
      return (
        <>
          <p>Connect your wallet!</p>
          <button
            disabled={isAuthenticating}
            className="secondary-btn"
            onClick={async () => {
              setIsUserDoneInitAuth(true);
              await connectWallet();
            }}
          >
            <img
              src="/images/metamask.png"
              alt="Metamask icon"
              width={30}
              height={30}
            />
            Metamask
          </button>
        </>
      );

    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
        <div
          style={{
            border: '1px solid green',
            borderRadius: 8,
            padding: 16,
            display: 'flex',
            justifyContent: 'center',
            gap: 16,
          }}
        >
          <img
            src="/images/metamask.png"
            alt="wallet-icon"
            width={20}
            height={20}
          />
          <span
            style={{ color: 'green', lineHeight: '1.75rem', fontWeight: 600 }}
          >
            {account}
          </span>
        </div>
        <button
          className="secondary-btn"
          style={{ alignSelf: 'center' }}
          onClick={disconnectWallet}
        >
          <span style={{ color: 'tomato' }}>Disconnect wallet</span>
        </button>
      </div>
    );
  };

  return (
    <div className="connect-wallet-modal" onClick={closeWalletModal}>
      <div
        onClick={(e) => e.stopPropagation()}
        className="connect-wallet-modal-content"
      >
        {renderContent()}
      </div>
    </div>
  );
};
