import { useEffect, useState } from 'react';
import { Web3Provider } from '@ethersproject/providers';
import { Contract } from '@ethersproject/contracts';
import { parseUnits } from '@ethersproject/units';

import { toHexString } from '../utils/strings';
import environments from '../utils/environments';
import { StakingABI, LpTokenABI } from '../assets/abis';

const { NETWORK_ID, STAKING_CONTRACT_ADDRESS, CURRENCY_DECIMALS } =
  environments;

const { ethereum } = window;

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const contracts = {
  staking: { address: STAKING_CONTRACT_ADDRESS, abi: StakingABI.abi },
  lpToken: { abi: LpTokenABI.abi },
};

const getSigner = () => {
  if (!ethereum) return null;

  const provider = new Web3Provider(ethereum);
  const signer = provider.getSigner();
  return signer;
};

const getContract = (name, options = {}) => {
  const signer = getSigner();
  if (!signer) return null;

  const contract = contracts[name];
  if (!contract) return null;
  return new Contract(
    options.address ?? contract.address,
    contract.abi,
    signer
  );
};

const useSmartContract = ({ account, user, connectWallet }, { pools }) => {
  const [pendingMirls, setPendingMirls] = useState([]);

  useEffect(() => {
    if (pools.length && account) getPendingMirl();
  }, [account, pools]);

  const checkNetwork = async () => {
    if (!ethereum) return;

    if (ethereum.chainId !== toHexString(NETWORK_ID)) {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: toHexString(NETWORK_ID) }],
      });
    }
  };

  const checkBeforeTransaction = async () => {
    await checkNetwork();

    if (!account || account !== user?.uid) {
      await connectWallet();
    }
  };

  const getTokenBalance = async (tokenAddress) => {
    await checkBeforeTransaction();
    const LPTokenContract = getContract('lpToken', { address: tokenAddress });
    const res = await LPTokenContract.balanceOf(user.uid);
    
    // const balance = Number(res.toString());
    // console.log({res, res1: res.toNumber(), balance});
    return res;
  };

  const getPendingMirl = async () => {
    await checkBeforeTransaction();
    const StakingContract = getContract('staking');
    const pendings = [];

    for (let pool of pools) {
      const res = await StakingContract.pendingMirl(pool.id, account);
      const pending = Number(res.toString()) / 10 ** CURRENCY_DECIMALS;
      pendings.push(pending);
    }
    setPendingMirls(pendings);
  };

  const getCurrentBalances = async (poolId) => {
    await checkBeforeTransaction();
    const StakingContract = getContract('staking');
    const pool = await StakingContract.poolInfo(poolId);

    const totalStakedAmount = Number(pool.totalSupply.toString()) / 10 ** CURRENCY_DECIMALS;

    const stakedAmountBigNumber = await StakingContract.balanceOf(poolId, account);
    const stakedAmount = Number(stakedAmountBigNumber.toString()) / 10 ** CURRENCY_DECIMALS;
    return { totalStakedAmount, stakedAmount };
  };

  const approve = async (amount, tokenAddress) => {
    try {
      await checkBeforeTransaction();
      const LPTokenContract = getContract('lpToken', { address: tokenAddress });
      const tx = await LPTokenContract?.approve(
        STAKING_CONTRACT_ADDRESS,
        amount
      );
      const res = await tx.wait();
      return res;
    } catch (err) {
      const message = err.error?.data?.message
        ? err.error?.data?.message.replace('execution reverted: ', '')
        : err.message;
      const errMessage = message.includes('user rejected transaction')
        ? 'Transaction is rejected'
        : message;
      throw new Error(errMessage);
    }
  };

  const deposit = async (poolId, amount, tokenAddress) => {
    try {
      let realAmount = parseUnits(amount.toString(), CURRENCY_DECIMALS);
      const walletBalance = await getTokenBalance(tokenAddress);
      if(realAmount > walletBalance) realAmount = walletBalance;
      const res = await approve(realAmount, tokenAddress);
      if (res.status === 1) {
        const StakingContract = getContract('staking');
        const tx = await StakingContract?.deposit(poolId, realAmount);
        const { transactionHash } = await tx.wait();
        const { totalStakedAmount, stakedAmount } = await getCurrentBalances(poolId);
        return { totalStakedAmount, stakedAmount, transactionHash };
      }
    } catch (err) {
      const message = err.error?.data?.message
        ? err.error?.data?.message.replace('execution reverted: ', '')
        : err.message;
      const errMessage = message.includes('user rejected transaction')
        ? 'Transaction is rejected'
        : message;
      throw new Error(errMessage);
    }
  };

  const withdraw = async (poolId, amount) => {
    try {
      const realAmount = parseUnits(amount.toString(), CURRENCY_DECIMALS).sub(1);
      console.log({realAmount});
      await checkBeforeTransaction();
      await delay(1000);

      const StakingContract = getContract('staking');
      const tx = await StakingContract?.withdraw(poolId, realAmount);
      const { transactionHash } = await tx.wait();
      const { totalStakedAmount, stakedAmount } = await getCurrentBalances(poolId);
      return { totalStakedAmount, stakedAmount, transactionHash };
    } catch (err) {
      const message = err.error?.data?.message
        ? err.error?.data?.message.replace('execution reverted: ', '')
        : err.message;
      const errMessage = message.includes('user rejected transaction')
        ? 'Transaction is rejected'
        : message;
      throw new Error(errMessage);
    }
  };

  const claim = async (poolId) => {
    try {
      await checkBeforeTransaction();
      await delay(1000);

      const StakingContract = getContract('staking');
      const tx = await StakingContract?.harvest(poolId);
      const res = await tx.wait();
      return res;
    } catch (err) {
      const message = err.error?.data?.message
        ? err.error?.data?.message.replace('execution reverted: ', '')
        : err.message;
      const errMessage = message.includes('user rejected transaction')
        ? 'Transaction is rejected'
        : message;
      throw new Error(errMessage);
    }
  };

  return {
    pendingMirls,
    getTokenBalance,
    getPendingMirl,
    deposit,
    withdraw,
    claim,
  };
};

export default useSmartContract;
