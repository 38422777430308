import StakingProductionABI from './production/Staking.json';
import LpTokenProductionABI from './production/LpToken.json';

import StakingStagingABI from './staging/Staking.json';
import LpTokenStagingABI from './staging/LpToken.json';

import environments from '../../utils/environments';

const isProduction = environments.ENVIRONMENT === 'production';

export const StakingABI = isProduction ? StakingProductionABI : StakingStagingABI;
export const LpTokenABI = isProduction ? LpTokenProductionABI : LpTokenStagingABI;
