import { useEffect, useState } from 'react';
import { doc, onSnapshot, setDoc } from 'firebase/firestore';

import { firestore } from '../configs/firebase.config';
import { callables } from '../utils/functions';

export default function useProfile(user) {
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    let unsubscribe;

    if (user) {
      unsubscribe = onSnapshot(
        doc(firestore, 'users', user.uid),
        async (document) => {
          if (document.exists()) {
            const user = {
              id: document.id,
              ...document.data(),
            };
            setProfile(user);
          } else {
            setProfile(null);
          }
        }
      );
    } else {
      setProfile(null);
    }

    return () => unsubscribe && unsubscribe();
  }, [user]);

  const updateEmail = async (email) => {
    await callables.updateEmail({ email });
  };

  const disconnectEmail = async () => {
    await callables.removeLinkings({ type: 'email' });
  };

  const disconnectTwitter = async () => {
    await callables.removeLinkings({ type: 'twitter' });
  };

  const disconnectInstagram = async () => {
    await callables.removeLinkings({ type: 'instagram' });
  };

  return {
    profile,
    updateEmail,
    disconnectEmail,
    disconnectTwitter,
    disconnectInstagram,
  };
}
