import { Container } from '@mui/material';

import Header from './Header';
import Footer from './Footer';
import { ConnectWalletModal } from './ConnectWallet';

const Layout = ({ children, hideFooter, className }) => {
  return (
      <div id="layout">
        <Header />
        <Container className={`main-content ${className}`}>
          <ConnectWalletModal />
          {children}
        </Container>
        {!hideFooter && <Footer />}
      </div>
  );
};

export default Layout;
