export const toHexString = (decimalString) =>
  '0x' + parseInt(decimalString).toString(16);

export const formatBigNumber = (number, digitsCount = 2) =>
  number.toLocaleString(undefined, {
    minimumFractionDigits: digitsCount,
    maximumFractionDigits: digitsCount,
  });

export const capitalize = (string) => {
  if (!string) return '';
  return string[0].toUpperCase() + string.substring(1);
};
