import React from 'react';
import ReactDOM from 'react-dom/client';
import { createTheme, ThemeProvider } from '@mui/material';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppContextProvider } from './contexts/app.context';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#8600ed', // purple
    },
    secondary: {
      main: '#d0c6ff', // very-light-purple
    },
  },
  typography: {
    fontFamily: "'DarkerGrotesque', Helvetica Neue, sans-serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          fontFamily: "'Overpass', sans-serif",
          '&:hover': {
            boxShadow: 'none',
          },
          '&.MuiButton-sizeSmall': {
            fontSize: '16px',
          },
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AppContextProvider>
        <App />
      </AppContextProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
