import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import Countdown from 'react-countdown';

import './styles/main.scss';
import Layout from './components/Layout';
import StakingPoolItem from './components/StakingPoolItem';
import { ConnectWalletContainer } from './components/ConnectWallet';
import DepositModal from './components/DepositModal';
import WithdrawModal from './components/WithdrawModal';
import ClaimModal from './components/ClaimModal';
import useAppContext from './hooks/useAppContext';

function App() {
  const {
    poolsState: { pools, startDate },
    responsiveState: { isSm },
  } = useAppContext();
  const [depositingPool, setDepositingPool] = useState(null);
  const [withdrawingPool, setWithdrawingPool] = useState(null);
  const [claimingPool, setClaimingPool] = useState(null);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Description />;
    } else {
      return (
        <>
          <Typography align="center">
            {days > 0 && (
              <>
                <strong style={{ fontSize: 48, marginLeft: 48 }}>{days}</strong>{' '}
                Days
              </>
            )}
            {(days > 0 || hours > 0) && (
              <>
                <strong style={{ fontSize: 48, marginLeft: 48 }}>
                  {hours}
                </strong>{' '}
                Hours
              </>
            )}
            {(days > 0 || hours > 0 || minutes > 0) && (
              <>
                <strong style={{ fontSize: 48, marginLeft: 48 }}>
                  {minutes}
                </strong>{' '}
                Mins
              </>
            )}
            <strong style={{ fontSize: 48, marginLeft: 48 }}>{seconds}</strong>{' '}
            Secs
          </Typography>
          <Typography textAlign="center">
            Estimated Target Date: <strong>{startDate.toString()}</strong>
          </Typography>
        </>
      );
    }
  };

  return (
    <Layout>
      <Box
        width="100%"
        py="0 !important"
        display="flex"
        flexDirection={isSm ? 'column-reverse' : 'row'}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <h1>MIRL Staking</h1>
        </Box>
        <ConnectWalletContainer />
      </Box>
      <Box bgcolor="rgba(0, 0, 0, 0.4)" borderRadius="20px" p={5} pt={0}>
        <h2>$MIRL Token Staking</h2>
        {startDate ? (
          <Countdown date={startDate} renderer={renderer} />
        ) : (
          <Description />
        )}
        <Box
          display="flex"
          flexDirection={isSm ? 'column' : 'row'}
          gap={5}
          px={3}
          mt={5}
        >
          {pools.map((pool) => (
            <StakingPoolItem
              key={pool.id}
              pool={pool}
              onClickDeposit={() => setDepositingPool(pool)}
              onClickWithdraw={() => setWithdrawingPool(pool)}
              onClickClaim={() => setClaimingPool(pool)}
            />
          ))}
        </Box>
      </Box>
      <DepositModal
        isOpen={Boolean(depositingPool)}
        onClose={() => setDepositingPool(null)}
        pool={depositingPool}
      />
      <WithdrawModal
        isOpen={Boolean(withdrawingPool)}
        onClose={() => setWithdrawingPool(null)}
        pool={withdrawingPool}
      />
      <ClaimModal
        isOpen={Boolean(claimingPool)}
        onClose={() => setClaimingPool(null)}
        pool={claimingPool}
      />
    </Layout>
  );
}

const Description = () => (
  <Typography textAlign="justify">
    Stake your MIRL or MIRL/USDT Uniswap LP to boost your rewards and unlock
    exclusive access/bonus to MIRL ecosystem products!
  </Typography>
);

export default App;
